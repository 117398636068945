<template>
  <loading :active="loading" :is-full-page="true"></loading>

  <esg-header></esg-header>

  <main>
    <carousel-cards></carousel-cards>

    <div class="description">
      <h2 style="text-transform: uppercase">Diagnóstico ESG</h2>
      <h3>
        O Diagnóstico ESG demonstra como a sua empresa está posicionada em relação ao
        grau de implementação de estratégias ESG, a partir das temáticas
        avaliadas no diagnóstico. A nota se apresenta em uma escala de 0% a
        100%, para cada eixo, Ambiental (E), Social (S) e de Governança (G).
      </h3>
    </div>

    <div class="main-content justify-content-between">
      <div class="map-radar-ods" v-if="esgDataIndividual.length > 0">
        <div class="map-radar-graph-itens">
          <img
            class="mostrar-svg"
            src="../images/esg-icon.svg"
            v-if="mostrar"
          />
          <div
            class="mostrar-div"
            v-bind:style="{ backgroundColor: esgColors[index] }"
            v-else
          >
            <p>
              {{
                esgDataIndividual[index]
                  .toFixed(1)
                  .toString()
                  .replace(".", ",")
              }}%
            </p>
            <img :src="require('./../images/' + icons[index] + '.png')" />
          </div>
          <canvas class="chart"></canvas>
        </div>

        <div class="map-radar-ods-graph">
          <div class="map-radar-cards">
            <div
              v-for="(eixo, index) in labels"
              v-bind:key="eixo"
              class="map-radar-card"
              :style="{ 'background-color': esgColors[index] }"
              v-on:click.prevent="change_axle_esg(index)"
            >
              <div class="map-radar-card-text">
                <span>{{ eixo }}</span>
              </div>
              <div class="map-radar-card-img">
                <img :src="require('./../images/' + icons[index] + '.png')" />
              </div>
            </div>
          </div>

          <div class="map-radar-graph"></div>
        </div>

        <div class="map-radar-ods-details">
          <div class="map-radar-ods-details">
            <div class="map-radar-details">
              <div
                class="map-radar-details-title"
                v-on:click.prevent="openEsgAxle()"
                :style="{
                  'background-color': esgColors[index],
                }"
              >
                <img :src="require(`./../images/${icons[index]}.png`)" />
                <h2 v-tooltip="labels[index]" class="text-truncate">
                  {{ labels[index] }}
                </h2>
                <span v-if="open" key="openEsgAxle">
                  <i class="material-icons">expand_more</i>
                </span>
                <span v-else>
                  <i class="material-icons">expand_less</i>
                </span>
              </div>

              <div class="map-radar-details-description">
                <p class="map-radar-details-description-sub">
                  {{ $t("esg." + labels[index] + ".title") }}
                </p>
                <span
                  v-if="!open"
                  key="clique"
                  v-on:click.prevent="openEsgAxle()"
                >
                  <b>{{ $t("map.texts.click_max") }}</b>
                </span>
                <div v-if="open">
                  <p
                    class="map-radar-details-description-desc"
                    key="openEsgAxle"
                    v-html="$t('esg.' + labels[index] + '.description')"
                  ></p>
                </div>
                <span v-if="open" v-on:click.prevent="openEsgAxle()">
                  <b>{{ $t("map.texts.click_min") }}</b>
                </span>
              </div>
              <div
                class="map-radar-details-line"
                v-on:click.prevent="openEsgAxle()"
              >
                <div
                  class="map-radar-details-line-color"
                  :style="{
                    'background-color': esgColors[index],
                  }"
                ></div>
              </div>
              <div class="map-radar-details-footer">
                <img src="./../images/esg-icon.svg" alt />
                <h2>
                  {{
                    esgDataIndividual[index]
                      .toFixed(1)
                      .toString()
                      .replace(".", ",")
                  }}%
                </h2>
                <h3
                  v-tooltip="'de Alinhamento ao eixo ' + labels[index]"
                  class="text-truncate"
                >
                  de Alinhamento ao eixo {{ labels[index] }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <footer>
    <img src="../images/barra_ods.svg" alt="Barra" class="w-100" />

    <div class="footer-items">
      <powered-by class="text-center"></powered-by>
      <button
        type="button"
        class="map-footer-button-back"
        @click="create_pdf()"
      >
        <i class="material-icons">file_download</i>
        {{ $t("map.button_download") }}
      </button>
      <button type="button" class="btn-seall" @click="goToFreemium()">
        Mapa da Transformação
      </button>
      <button
        class="
          d-flex
          align-items-center
          justify-content-center
          btn btn-outline-danger
        "
        v-if="canDelete"
        @click="onDelete()"
      >
        <i class="material-icons">delete</i>
        Deletar
      </button>
    </div>
  </footer>
</template>

<script>
import { groupBy } from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service.js";
import "../javascript/chart.bundle.min.js";
import "../javascript/chartjs-plugin-datalabels";
import "../javascript/chartjs-plugin-labels";
import CarouselCards from "./CarouselCards.vue";
import EsgHeader from "./EsgHeader.vue";
import PoweredBy from "./PoweredBy.vue";
export default {
  name: "Perguntas",
  components: {
    Loading,
    EsgHeader,
    PoweredBy,
    CarouselCards,
  },
  data() {
    return {
      cogId: null,
      loading: false,
      open: false,
      organization: null,
      quiz: null,
      axles: [],
      canSave: false,
      g4Completed: false,
      chart: null,
      esgGraph: [],
      labels: ["Ambiental", "Governança", "Social"],
      icons: ["esg-ambiente", "esg-governanca", "esg-social"],
      esgColors: ["#00B7BB", "#6A11CA", "#E65F0C"],
      esgData: [],
      esgDataIndividual: [],
      mostrar: true,
      canDelete: false,
      orgCanDeleteResult: [
        "gabriel_castr@outlook.com.br",
        "gabriel@sealegacy.com.br",
        "ferollagfv@gmail.com",
      ],
      index: 0,
    };
  },
  mounted() {
    this.$i18n.locale = "pt";
    this.cogId = AuthService.getCogId();
    const email = AuthService.getEmail();
    this.canDelete = this.orgCanDeleteResult.includes(email);
    this.loading = true;
    OrganizationService.getG4Quiz(this.cogId)
      .then((org) => {
        const questions = org.data.filter(
          (q) => q.axle != "Conceitos e Relevância"
        );
        this.quiz = groupBy(questions, (q) => q.axle);
        this.axles = this.labels;

        if (this.quizQuantityNotAnswered().length > 0) {
          window.location = "/esg";
        }
        this.calculateEsgData();
        this.generateChart();
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        this.$notify({
          title: this.$t("common.error_http"),
          type: "error",
        });
      });
  },
  methods: {
    quizQuantityNotAnswered() {
      return this.axles.filter((t) => {
        return this.quiz[t].some((q) => q.answer.answerId == null);
      });
    },
    openEsgAxle() {
      return (this.open = !this.open);
    },
    generateChart() {
      const labelImage = [
        {
          src:
              process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-ambiente.png',
          width: 40,
          height: 40,
        },
        {
          src: process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-governanca.png',
          width: 40,
          height: 40,
        },
        {
          src: process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-social.png',
          width: 40,
          height: 40,
        },
      ];

      const self = this;

      setTimeout(() => {
        this.chart = new Chart(document.getElementsByClassName("chart"), {
          type: "doughnut",
          data: {
            labels: self.labels,
            datasets: [
              {
                data: this.esgData,
                fill: false,
                backgroundColor: self.esgColors,
                borderColor: self.esgColors,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                anchor: "end",
                align: "start",
              },
              labels: {
                render: "image",
                textMargin: -60,
                images: labelImage,
              },
            },
            tooltips: { enabled: false },
            legend: { display: false },
            responsive: false,
            onClick: function (e, activeElement) {
              var bar = this.getElementAtEvent(e)[0];
              var index = bar._index;

              self.chart.fillStyle = self.esgColors[self.index];

              self.mostrar = false;
              self.index = index;

              this.update();
            },
          },
        });
      }, 500);
    },
    change_axle_esg(index) {
      this.index = index;
      this.mostrar = false;
    },
    calculateEsgData() {
      let sumTotal = 0;
      const answerByAxle = [];
      this.axles.forEach((ax) => {
        const questions = this.quiz[ax];
        let sumRating = 0;
        questions.forEach((question) => {
          const answer = question.answer;
          const optionAnswer = question.options.find(
            (opt) => opt.id === answer.answerId
          );
          const ratingOfAnswer = optionAnswer ? optionAnswer.rating : 0;
          sumRating = sumRating + ratingOfAnswer;
        });
        sumTotal = sumTotal + sumRating;
        answerByAxle.push(sumRating);
        this.esgDataIndividual.push((sumRating * 100) / (5 * questions.length));
      });

      answerByAxle.forEach((a) => {
        this.esgData.push((a * 100) / sumTotal);
      });
    },
    create_pdf() {
      var a = document.createElement("a");
      a.setAttribute("target", "_blank");
      a.setAttribute("href", "/esg/radar/pdf");
      a.click();
    },
    goToFreemium() {
      this.$toast.success(
        ` <h4><b>Questionário finalizado com sucesso!</b></h4>
              <p>Deseja iniciar o seu Mapa da Transformação?</p>
              <div class="d-flex justify-content-between">
                <a href="/map" class="btn btn-success">Quero</a>
                <a href="#" class="btn btn-secondary">Depois</a>
              </div>`,
        {
          type: "success",
          duration: false,
        }
      );
    },
    onDelete() {
      this.loading = true;
      OrganizationService.deleteG4Quiz(this.cogId)
        .then(() => {
          this.loading = false;
          this.$toast.success(
            ` <h4><b>Questionário deletado</b></h4>
              <p>Deseja Refazer seu Questionário?</p>
              <div class="d-flex justify-content-between">
                <a href="/esg" class="btn btn-success">Quero</a>
                <a href="#" class="btn btn-secondary">Não</a>
              </div>`,
            {
              type: "success",
              duration: false,
            }
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "../css/bootstrap/bootstrap.min.css";
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px lightgray;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #492e85;
  border-radius: 5px;
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

main {
  background-color: #e5e5e5;
  position: relative;

  .description {
    background: #ffffff;
    padding: 5vh 10vw;
    h2 {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 133%;
      text-align: center;
      color: #533a8b;
      margin: 0vh 0vw;
      padding: 5vh 0vw 0vh 0vw;
    }

    h3 {
      color: #ffffff;
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 125%;
      text-align: center;
      color: #a3a3a3;
      text-align: center;
      margin: 0vh 0vw;
      padding: 2vh 0vw 0vh 0vw;
    }
  }

  .main-content {
    padding: 5vh 15vw;
    background: #ffffff;
    .card-header {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      color: #533a8b;
      background-color: #e5e5e5;
    }

    form {
      .label-radio {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #a3a3a3;

        &:hover input ~ .checkmark {
          background-color: #ccc;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          &:checked ~ .checkmark {
            background-color: #533a8b;
          }
          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #eee;
          border-radius: 50%;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }

        .checkmark:after {
          top: 7px;
          left: 7px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
        }
      }
      .option-selected {
        font-weight: 500;
        color: #533a8b;
      }
    }

    .mostrar-svg {
      position: absolute;
      z-index: 5;
      width: 10%;
      height: 10%;
    }

    .map-radar-ods {
      background-color: #e5e5e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5vh 0vw;
    }

    .map-radar-ods h2 {
      margin: 0vh 0vw;
      padding: 2vh 0vw;
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 133%;
      text-align: center;
      color: #492e85;
    }

    .map-radar-ods h3 {
      margin: 0vh 0vw;
      padding: 0vh 0vw 3vh;
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 133%;
      text-align: center;
      color: #a3a3a3;
    }

    .map-radar-ods-graph {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      flex-wrap: wrap;
      /*background: #FFFFFF;*/
      /*box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2), 0px 6px 10px rgba(112, 119, 128, 0.14), 0px 1px 18px rgba(112, 119, 128, 0.12);*/
      border-radius: 16px;
      padding: 4vh 2vw;
    }

    .map-radar-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      text-align: left;
      padding: 0vh 0vw;
      margin: 0vh 0vw;
    }

    .map-radar-card {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      text-align: left;
      padding: 1vh 0.5vw;
      margin: 0vh 0.5vw;
      height: 140px;
      border-radius: 10px;
      width: 140px;
      margin-bottom: 1rem;
    }

    .map-radar-card-text {
      font-family: Poppins-Regular;
      display: flex;
      flex-direction: row;
      height: 30%;
      width: 100%;
      padding: 0.5vh 0vw;
    }

    .map-radar-card span {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding: 5px 0 0 5px;
      margin: 0;
      color: #ffffff;
      font-family: Poppins-Regular;
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
    }

    .map-radar-card-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
      height: 60%;
      margin-top: 1rem;
    }

    .map-radar-card-img img {
      height: 60%;
    }

    .map-radar-graph {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .map-radar-graph img {
      width: 90%;
    }

    .map-radar-ods-details {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 5vh 0vw;
      width: 100%;
    }

    .map-radar-details {
      box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
        0px 6px 10px rgba(112, 119, 128, 0.14),
        0px 1px 18px rgba(112, 119, 128, 0.12);
      align-items: center;
      border-radius: 5vh 5vh;
      display: flex;
      flex-direction: column;
      width: 60vw;
    }

    .map-radar-details-title {
      border-radius: 5vh 5vh 0 0;
      display: flex;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      padding: 2vh 2vw;
      width: 100%;
    }

    .map-radar-details-title .material-icons {
      color: #ffffff;
    }

    .map-radar-details-title img {
      min-width: 100px;
      min-height: 100px;
      width: 5%;
    }

    .map-radar-details-title h2 {
      margin: 0 0 0 1rem;
      padding: 0vh 0vw;
      font-family: "Oswald", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 56px;
      line-height: 120%;
      text-align: left;
      color: #ffffff;
      width: 100%;
    }

    .map-radar-details-title h3 {
      margin: 0vh 0vw;
      padding: 0vh 0vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: "Oswald", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 120%;
      color: #ffffff;
      text-align: left;
      width: 70%;
    }

    .map-radar-details-title p {
      color: #ffffff;
      font-family: "Oswald", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      opacity: 1;
      width: 10%;
    }

    .map-radar-details-description {
      background-color: #ffffff;
      padding: 2vh 2vw;
      width: 100%;
    }

    .map-radar-details-description span {
      cursor: pointer;
      font-family: Nunito-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      color: #a3a3a3;
      padding: 0vh 5vw;
    }

    .map-radar-details-description-sub {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 133%;
      color: #666666;
      text-align: left;
      padding: 0vh 5vw;
    }

    .map-radar-details-description-desc {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      color: #707780;
      text-align: left;
      padding: 0vh 5vw;
    }

    .map-radar-details-line {
      width: 100%;
      background: #e6e9ed;
    }

    .map-radar-details-line-color {
      height: 1vh;
    }

    .map-radar-details-footer {
      align-items: center;
      border-radius: 0 0 5vh 5vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;
      padding: 2vh 2vw;
      width: 100%;
      min-height: 10vh;
    }

    .map-radar-details-footer img {
      width: 5%;
      min-width: 100px;
    }

    .map-radar-details-footer h2 {
      margin: 0vh 0vw;
      padding: 0vh 0vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 2.5vw;
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      color: #707780;
    }

    .map-radar-details-footer h3 {
      margin: 0vh 0vw;
      padding: 0vh 1vw;
      display: block;
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 120%;
      color: #707780;
    }

    .map-radar-details-footer .material-icons {
      color: #707780;
      width: 10%;
    }
  }
}

footer {
  height: 0vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;

  .footer-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0vh 20vw;
  }
  .btn-seall {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 5vh 0vw;
    padding: 1vh 3vw;
  }
  .map-footer-button-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #2f1764;
    background-color: #ffffff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 5vh 0vw;
    padding: 1vh 3vw;
  }
}

.v--default-css .c-toast--success {
  background-color: #492e85;
}

.map-radar-graph-itens {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  width: 100%;
  height: 100%;
  max-height: 900px;
  max-width: 900px;
  z-index: 10;
}

.mostrar-svg {
  position: absolute;
  z-index: 5;
}

.mostrar-div {
  width: 222px;
  height: 222px;
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 6vh;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 1px 0px 10px 5px rgba(255, 255, 255, 0.75);
  p {
    margin: 0 !important;
    font-family: Poppins-Regular !important;
  }
  img {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-map {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .btn-add {
    top: 25% !important;
  }

  .div-map-header-links {
    text-decoration: none;
    padding: 3vh 0vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .div-map-header-photo {
    justify-content: center;
    width: 100%;
  }

  .div-map-header-photo img {
    border-radius: 100%;
    overflow: hidden;
    height: 10%;
    width: 10%;
  }

  .div-map-header-img img {
    width: 40vw;
  }

  .legacy-card {
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    height: 10vh;
    margin: 0vh 0vw;
    padding: 5vh 3vw;
    width: 75vw;
  }

  .legacy-card img {
    width: 10vw;
  }

  .map-missao-organization h3 {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
  }

  .map-radar-card {
    margin: 0.5vh 5vw;
    width: 35vw;
    height: 20vh;
  }

  .map-radar-details {
    width: 90vw;
  }

  .map-radar-details h2 {
    font-size: 30px;
  }

  .map-radar-details h3 {
    font-size: 20px;
  }

  .map-radar-details-line {
    width: 100%;
    background: #e6e9ed;
  }

  .map-radar-details-line-color {
    left: 0vw;
  }

  .map-radar-details-description-sub {
    font-size: 20px;
  }

  .map-radar-details-description-dec {
    font-size: 15px;
  }

  .map-radar-details-footer h2 {
    font-size: 20px;
  }

  .map-radar-details-footer h3 {
    padding: 0vh 3vw;
    font-size: 15px;
  }

  .footer-map {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .div-map-footer-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1vh 0vw;
    margin: 0vh 0vw;
  }

  .div-map-footer-button .material-icons {
    color: #2f1764;
  }

  .map-footer-button-back {
    color: #2f1764;
    background-color: #ffffff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0vh 1vw;
    width: 60vw;
  }

  .map-footer-button {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0.5vh 3vw;
    width: 75vw;
  }

  .div-map-footer-button {
    button,
    a {
      width: 200px;
    }

    .actions {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }
  }

  .mostrar-svg {
    position: absolute;
    z-index: 5;
    width: 10%;
    height: 10%;
  }

  .mostrar-div img {
    width: 15vw;
  }

  .map-footer-button-back {
    color: #2f1764;
    background-color: #ffffff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0vh 1vw;
    width: 60vw;
  }
}
</style>
